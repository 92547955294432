<style lang="scss" scoped>
.text {
	margin-top: 30vh;
	font-size: 0.3rem;
	color: #052433;
	line-height: 0.3rem;
	font-weight: bold;
	text-align: center;
}
</style>

<template>
	<div>
		<div class="text">订单支付中...</div>
		<div v-html="content"></div>
	</div>
</template>

<script>
// 说明：由于支付宝取消支付后当前页面会被关闭，所以调用支付不能与订单详情在同一个页面，所以才加了这个页面
// 以后要优化，关闭支付要跳转到订单支付页，去除这个中间页
export default {
	name: 'aLiPay', // 支付宝支付页
	data() {
		return {
			content: '',
		};
	},
	created() {
		this.setHtml();
	},
	methods: {
		setHtml() {
			let query = ap.parseQueryString();
			this.content = query.content;
			console.log(query);
			this.$nextTick(() => {
				document.forms[0].submit();
			});
		},
	},
};
</script>
